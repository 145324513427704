import './App.css';
import React from 'react';
import Viewer from './components/viewer';
import BernMap from './components/map';
import Footer from './components/footer';
import Admin from './components/admin';
import News from './components/news';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';

function App() {

  return (
      <BrowserRouter>
        <Routes>
          <Route path = "/" element = {
            <>
              <Viewer />
              <BernMap />
              <Footer />
            </>
          }/>

          <Route path = "/admin" element = {<Admin />}/>
          <Route path = "/news" element = {<News />}/>
        </Routes>
      </BrowserRouter>
  );
}

export default App;