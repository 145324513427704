import { createSlice } from '@reduxjs/toolkit';

const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    expanded: false,
    render: 'start',
    navbar: 'normal'
  },
  reducers: {
    expandNavbar: (state, action) => {
      state.expanded = action.payload;
    },
    setRender: (state, action) => {
      state.render = action.payload;
    },
    setPosition: (state, action) => {
      state.navbar = action.payload;
    },
  },
});
export const { expandNavbar, setRender, setPosition } = navbarSlice.actions;
export const selectExpand = (state) => state.navbar.expanded;
export const currentRender = (state) => state.navbar.render;
export const navPosition = (state) => state.navbar.navbar;
export default navbarSlice.reducer;