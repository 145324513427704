import React, { useState, useEffect, useRef } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import Overlay from 'ol/Overlay';
import { fromLonLat } from 'ol/proj';
import { TileImage } from 'ol/source';
import 'ol/ol.css';
import  { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { setRender, currentRender} from "../redux/navbarSlice";
import { useDispatch, useSelector } from "react-redux";

const BernMap = () => {
 
    const dispatch = useDispatch();
    const actualRender = useSelector(currentRender);
    const locationRef = useRef();
    const container = document.getElementById('root');
    const currentScroll = container.scrollTop;

    const [ scrollPosition, setScrollPosition ] = useState(0);
    const [ largeScreen, setLargeScreen ] = useState(window.innerWidth > 1000);
    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(container.scrollTop);
        };
        const handleResize = () => {
            setLargeScreen(window.innerWidth > 1000);
        }
        container.addEventListener('scroll', handleScroll);
        container.addEventListener('resize', handleResize);

        let timeoutId;
        if(actualRender === 'locationOne') {
            locationOneRef.current.scrollIntoView({
                block: 'center',
            });
            setLocationColor('firstLocation');
        } else if(actualRender === 'locationTwo') {
            locationTwoRef.current.scrollIntoView({
                block: 'center',
            });
            setLocationColor('secondLocation');
        }
        
        timeoutId = setTimeout(() => {
            dispatch(setRender('start'));
        }, 1000)

        return () => {
            clearTimeout(timeoutId); 
            container.removeEventListener('scroll', handleScroll);
            container.removeEventListener('resize', handleResize);
        }
    }, [actualRender, currentScroll]);


    const mapRef = useRef(null);
    const firstRef = useRef(null);
    const secondRef = useRef(null);
    const thirdRef = useRef(null);
    const fourthRef = useRef(null);

    //refs for the locations, for the scroll effect
    const locationOneRef = useRef();
    const locationTwoRef = useRef();
    const [ locationColor, setLocationColor ] = useState(null);
    
    const handleMarkerClick = (input) => {
        switch(input) {
            case 'firstLocation':
                locationOneRef.current.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
                setLocationColor('firstLocation');
                break;
            case 'secondLocation':
                locationTwoRef.current.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
                setLocationColor('secondLocation');
                break;
            case 'noLocation':
                setLocationColor('noLocation');
                container.style.overflow = 'hidden';
                break;
            case 'close':
                setLocationColor(null);
                container.style.overflowY = 'auto';
                break;
        }
    };

    const handleContactClick = () => {
        setLocationColor(null);
        container.style.overflow = 'auto';
        
        setTimeout(() => {
            dispatch(setRender('contact'));
        }, 500);
    };

    useEffect(() => {
        const blackWhite = new TileLayer({
            source: new TileImage({
                crossOrigin: 'anonymous',
                tilePixelRatio: 1,
                tileLoadFunction: (tile, src) => {
                    tile.getImage().src = src;
                },
                url: 'https://{a-c}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png'
            })
        });

        const firstMarker = document.createElement('div');
        firstMarker.className = 'first-marker';
        const image = document.createElement('img');
        image.src = '/images/logos/location.png';
        image.className = 'first-image';
        firstMarker.appendChild(image);
        firstRef.current = new Overlay({
            element: firstMarker,
            position: fromLonLat([7.388456, 46.937017]),
            positioning: 'bottom-center'
        });
        
        const secondMarker = document.createElement('div');
        secondMarker.className = 'second-marker';
        const secondImage = document.createElement('img');
        secondImage.src = '/images/logos/location.png';
        secondImage.className = 'first-image';
        secondMarker.appendChild(secondImage);
        secondRef.current = new Overlay({
            element: secondMarker,
            position: fromLonLat([7.440619, 46.965615]),
            positioning: 'bottom-center'
        });  
        
        const thirdMarker = document.createElement('div');
        thirdMarker.className = 'third-marker';
        const thirdImage = document.createElement('img');
        thirdImage.src = '/images/logos/target.png';
        thirdImage.className = 'first-image';
        thirdMarker.appendChild(thirdImage);
        thirdRef.current = new Overlay({
            element: thirdMarker,
            position: fromLonLat([7.47143, 46.93769]),
            positioning: 'bottom-center'
        });

        const fourthMarker = document.createElement('div');
        fourthMarker.className = 'third-marker';
        const fourthImage = document.createElement('img');
        fourthImage.src = '/images/logos/target.png';
        fourthImage.className = 'first-image';
        fourthMarker.appendChild(fourthImage);
        fourthRef.current = new Overlay({
            element: fourthMarker,
            position: fromLonLat([7.39716, 46.94868]),
            positioning: 'bottom-center'
        });

        firstMarker.addEventListener('click', () => handleMarkerClick('firstLocation'));
        secondMarker.addEventListener('click', () => handleMarkerClick('secondLocation'));
        thirdMarker.addEventListener('click', () => handleMarkerClick('noLocation'));
        fourthMarker.addEventListener('click', () => handleMarkerClick('noLocation'));

        const map = new Map({
            target: mapRef.current,
            layers: [
                blackWhite,
            ],
            view: new View({
                center: fromLonLat([7.4474, 46.9480]),
                zoom: 12,
                minZoom: 10,
                maxZoom: 16,
                extent: [
                    811133.33,   // min X
                    5918048.19,  // min Y
                    847163.10,   // max X
                    5951663.85   // max Y
                ]
            }),
            overlays: [firstRef.current, secondRef.current, thirdRef.current, fourthRef.current]
        });

        return () => {
            map.setTarget(null);
        };
    }, []);

    return (
        <>
        <div>
            <h1 className = "map-title" ref = {locationRef}>Standorte</h1>
            <div ref={mapRef} className="map-container" />

            <div className = "location-description">
                <div className = "under-map"><p>Im Moment sind wir an folgenden Standorten aktiv:</p></div>
                <div
                    className = {locationColor === 'firstLocation' ? 'active' : ''}
                    id = "first-location"
                >
                    <h4 ref = {locationOneRef} id = "locationRef">Standort 1: </h4>
                    <p>Quartier Kleefeld</p>
                    <p style = {{display: 'block'}}>Den Ursprung hat unser Projekt im Quartier Kleefeld. Hier haben wir unsere Bewegungsangebote zum Leben erweckt. Seit 2021 werden hier dreimal wöchentlich Bewegungsangebote für Kinder und Jugendliche durchgeführt. Betreut werden die Angebote von der Projektleitung sowie von jugendlichen Coaches aus dem Quartier selbst. Die Angebote werden laufend weiterentwickelt und den Wünschen und Bedürfnissen der Quartierbewohner:innen angepasst.</p>
                    </div>

                <div id = "second-location"
                    className = {locationColor === 'secondLocation' ? 'active' : ''}
                >
                    <h4 ref = {locationTwoRef}>Standort 2: </h4>
                    <p>Viererfeld</p>
                    <p style = {{display: 'block'}}>Die TUV ist eine Kollektivunterkunft für Flüchtlinge. Sie wurde modular aufgebaut für die Flüchtlinge aus dem Ukrainekrieg und im Juli 2022 geöffnet. Mittlerweile ist die Unterkunft auch geöffnet für Flüchtlinge aus anderen Ländern. 
Das Rock the Block Team wurde von der zuständigen Sozialarbeiterin, die unser Angebot im Kleefeld kannte, gebeten auch ein Bewegungsförderungsangebot in der TUV anzubieten. Seit März 2023 wird Rock the Block einmal pro Woche in der TUV durchgeführt. Im Gegensatz zum Standort im Kleefeld, verfolgen wir keine langfristigen Ziele mit den Teilnehmenden, da es eine hohe Fluktuation gibt in den Teilnehmenden. Unser Hauptziel ist es trotz Sprachbarrieren möglichst viele der ca. 130 Kinder und Jugendlichen für nachhaltige Bewegung zu motivieren. Dazu nutzen wir einfach erklärbare und bekannte Spiele.</p>
                    </div>
            </div>
        </div>

        <AnimatePresence>
        {locationColor === 'noLocation' && 
            <motion.div
            className = "no-location"
            initial={{scale: 0.2, backdropFilter: 'none'}}
            animate={{scale: 1, backdropFilter: 'blur(5px)'}}
            transition={{duration: 0.3}}
            exit={{scale: 0.5, transition: {duration: 0, delay: 0.3}}}
            >
                
                <motion.div
                initial={{scale: 0.5, opacity: 0}}
                animate={{scale: 1, opacity: 1}}
                transition={{duration: 0.3, delay: 0.5}} 
                exit={{scale: 0.5, opacity: 0, transition: {duration: 0.3}}}
                >
                <motion.button initial={{scale: 0.5, opacity: 0}}
                animate={{scale: 1, opacity: 1}}
                transition={{duration: 0.3, delay: 1}}
                onClick = {() => handleMarkerClick('close')} className = "location-closer"><img src = "/icons/close-blue.png" style = {{height: '100%', width: '100%', maxHeight: '30px', maxWidth: '35px'}}/></motion.button>
                <p>In diesem Quartier sind wir leider noch nicht aktiv. Doch mit deiner Hilfe könnten wir auch bald hier vor Ort sein.</p><p>Möchtest du ebenfalls ein Beitrag zur nachhaltigen Bewegungsförderung von Kindern und Jugendlichen leisten? Wir freuen uns über jede Unterstützung.</p><button className = "location-contact" onClick = {() => handleContactClick()}>Kontakt aufnehmen</button>
                </motion.div>

            </motion.div>
        }
        </AnimatePresence>
        
            <div className = "future">
            <h1>Wie geht es weiter?</h1>
                <div className = "future-grid">

                <div className = "future-left">
                    <p>Die positiven Erfahrungen aus dem Pilot-Quartier Kleefeld bestärken das Projektteam darin, die Angebote auf weitere Quartiere auszuweiten. Dazu werden in einer nächsten Phase weitere Quartiere eruiert, welche über eine besondere Notwendigkeit für Bewegungsförderung und eine Belebung des Quartiers verfügen.</p>
                    <h2>Ziele</h2>
                    <p className = "future-title">Neue Standorte: </p><p className = "future-inline">Aufbau von 1-2 neuen Standorten, damit noch mehr Kinder erreicht werden können.</p>
                    <br/><br/><p className = "future-title">Weiterentwicklung: </p><p className = "future-inline">Die Angebote im Pilot-Quartier Kleefeld werden weiterentwickelt damit noch mehr Quartierbewohnende, auch aus anderen Zielgruppen für Bewegung sensibilisiert werden können.</p>
                    <img src = "/images/viewer/rope.jpg" className = "future-image"/>
                </div>

                <div className = "future-right">
                    <ul className = "mission">
                    <h1>Unsere Mission</h1>
                        <li>Wir ermöglichen allen Menschen einen freien und hindernislosen Zugang zu Sport, Spiel und Bewegung.</li>
                        <li>Wir fördern die körperliche Aktivität der Kinder und Jugendlichen und den sozialen Austausch, indem im Quartier ein Standort entsteht, an welchem gemeinsam gespielt, gerannt und gelacht wird.</li>
                        <li>Wir setzen uns speziell für Quartiere ein, in welchen diese Bewegungskultur noch nicht gelebt wird oder ein Input für das soziale Klima nötig ist.</li>
                    </ul>

                    <ul className = "mission">
                    <h1>Unsere Werte</h1>
                        <li>Bewegung im Herz: Für unsere Passion setzen wir uns ein. Bewegung soll für alle zugänglich sein.</li>
                        <li>Integrativ: Wir bewegen uns alle zusammen. Egal wer, egal von wo, egal wie.</li>
                        <li>Flexibel: Wir gehen auf aktuelle Bedürfnisse ein und sorgen für schnelle Veränderungen im Quartier.</li>
                        <li>Partizipation: Die Quartierbewohner:innen bestimmen mit. Wir nehmen ihre Wünsche ernst.</li>
                        <li>Gleichberechtigung: Gleicher Zugang für alle. </li>
                    </ul>

                </div>

                </div> 

                <h1>Unsere Partner:innen</h1>
                <div className = "partners">
                    <p>Das Projekt Rock the Block wird von folgenden Partner:innen finanziert und unterstützt:</p>

                    <ul className = "partners-list">
                        <li className = "partners-item"><img src = "/images/logos/asuera.png" alt = "Logo"/>{largeScreen && <p>Asuera Stiftung</p>}</li>
                        <li className = "partners-item"><img src = "/images/logos/beisheim.png" alt = "Logo"/>{largeScreen && <p>Beisheim Stiftung</p>}</li>
                        <li className = "partners-item"><img src = "/images/logos/bekb.png" alt = "Logo"/>{largeScreen && <p>BEKB Förderfond</p>}</li>
                        <li className = "partners-item"><img src = "/images/logos/hubacher.png" alt = "Logo"/>{largeScreen && <p>Hans Hubacher Stiftung</p>}</li>{!largeScreen && <p>Hans Hubacher Stiftung</p>}
                        <li className = "partners-item"><img src = "/images/logos/lab.png" alt = "Logo"/>{largeScreen && <p>Innovationslabor lab7x1</p>}</li>{!largeScreen && <p>Innovationslabor lab7x1</p>}
                        <li className = "partners-item"><img src = "/images/logos/nydegger.png" alt = "Logo"/>{largeScreen && <p>T. Nydegger Stiftung</p>}</li>
                    </ul>

                    <p>Möchtest du ebenfalls ein Beitrag zur nachhaltigen Bewegungsförderung von Kindern und Jugendlichen leisten? Wir freuen uns über jede Unterstützung.</p>
                    <button onClick = {handleContactClick}>Rock the Block unterstützen</button>
                </div>  
            </div>
        </>
    );
}

export default BernMap;
