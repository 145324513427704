import React, { useState, useEffect, useRef, useMemo } from 'react';
import JoditEditor from "jodit-react";

const TextEditor = ({value, onChange}) => {
    const editor = useRef(null);

    const [content, setContent] = useState(value || '');

    useEffect(() => {
        setContent(value);
    }, [value]);

    const options = useMemo(() => [
        'bold', 'italic', '|', 'ul', '|', 'font', 'fontsize', '|', 
        'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 
        'table', 'link', '|', 'undo', 'redo',
    ], []);

    const handleChange = (newContent) => {
        setContent(newContent);
        if (onChange) onChange(newContent)
    };

    const config = useMemo(() => ({
        readonly: false,
        defaultActionOnPaste: 'insert_as_html',
        defaultLineHeight: 1.5,
        enter: 'div',
        buttons: options,
        buttonsMD: options,
        buttonsSM: options,
        buttonsXS: options,
        statusbar: false,
        toolbarAdaptive: false,
        // Sizes can be adjusted or removed if unnecessary
        sizeLG: 900,
        sizeMD: 700,
        sizeSM: 400,
    }), [options]);

    return (
        <div className='text-editor'>
            <JoditEditor
                ref={editor}
                value={content}
                config={config}
                onChange={handleChange}  // Directly pass handleChange
            />
        </div>
    );
};

export default TextEditor;