import React, { useState, useEffect, useRef } from 'react';
import TextEditor from './textEditor';

const Admin = () => {

    const [ largeScreen, setLargeScreen ] = useState(window.innerWidth > 900);
    useEffect(() => {
        const handleResize = () => {
            setLargeScreen(window.innerWidth > 900);
        };
        window.addEventListener('resize', handleResize);

        return() => {
            window.removeEventListener('resize', handleResize);
        }

    }, []);

    const [ loggedIn, setLoggedIn ] = useState(false);

    const [ userData, setUserData ] = useState({
        name: '',
        password: ''
    });

    const adminLogin = async() => {

        try {

            const response = await fetch('/login', {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify({name: userData.name, password: userData.password})
            });

            if(response.ok) {
                const data = await response.json();

                setLoggedIn(true);
            }
            
        } catch(err) {
            console.error(err);
        }
    };

    //getJobs and News
    const [ allJobs, setAllJobs ] = useState([]);
    const getJobs = async() => {

        try {

            const response = await fetch('/getJobs', {
                method: 'GET'
            });

            if(response.ok) {
                const data = await response.json();
                console.log(data);
                setAllJobs(data);
            }

        } catch(err) {
            console.error(err);
        }

    };

    const [ jobSelected, setJobSelected ] = useState(false);
    const [ jobInfo, setJobInfo ] = useState({
        id: '',
        title: '',
        description: '',
        pensum: '',
        address: '',
        date: '',
        tasks: [],
        qual: [],
        filename: "",
    });
    
    useEffect(() => {

        if(loggedIn) {
            getJobs();
        }

    }, [loggedIn]);
    useEffect(() => {

        if(!jobSelected && loggedIn) {
            getJobs();
        }

    }, [jobSelected, loggedIn]);

    const selectJob = async(job) => {
        setJobSelected(!jobSelected);

        try {

            const response = await fetch(`/getCurrentJob?job=${job.id}`, {
                method: 'GET'
            });

            if(response.ok) {
                const data = await response.json();
                console.log("data id", data.id);
                setJobInfo({
                    id: data.id,
                    title: data.job_title,
                    description: data.job_description,
                    pensum: data.pensum,
                    address: data.address,
                    date: data.date,
                    tasks: Array.isArray(data.tasks) ? data.tasks : [],
                    qual: Array.isArray(data.qual) ? data.qual : [],
                    active: data.active,
                    filename: data.filename !== null ? data.filename: ''
                });

            }

        } catch(err) {
            console.error(err);
        }
    };
    
    const createNewTask = (input) => {
        if(input === 'task') {
            setJobInfo({
                ...jobInfo,
                tasks: [...jobInfo.tasks, '']
              });
        } else if(input === 'qual') {
            setJobInfo({
                ...jobInfo,
                qual: [...jobInfo.qual, '']
              });
        } else if(input === 'info') {
            setJobInfo({
                ...jobInfo,
                info: [...jobInfo.info, '']
              });
        } else if(input === 'info_list') {
            setJobInfo({
                ...jobInfo,
                list: [...jobInfo.list, '']
              });
        }
    };
    const deleteTask = (index, input) => {

        if(input === 'task') {
            setJobInfo({
                ...jobInfo,
                tasks: jobInfo.tasks.filter((task, i) => i !== index)
            });
        } else if(input === 'qual') {
            setJobInfo({
                ...jobInfo,
                qual: jobInfo.qual.filter((task, i) => i !== index)
            });
        } else if(input === 'info') {
            setJobInfo({
                ...jobInfo,
                info: jobInfo.info.filter((task, i) => i !== index)
            });
        }

    };
    const changeJob = (event, currentTask, index) => {
         switch(currentTask) {
            case 'title':
                setJobInfo({
                    ...jobInfo,
                    title: event.target.value
                });
            break;
            case 'pensum':
                setJobInfo({
                    ...jobInfo,
                    pensum: event.target.value
                });
            break;
            case 'date':
                setJobInfo({
                    ...jobInfo,
                    date: event.target.value
                });
            break;
            case 'address':
                setJobInfo({
                    ...jobInfo,
                    address: event.target.value
                });
            break;
            case 'desc':
                setJobInfo({
                    ...jobInfo,
                    description: event.target.value
                });
            break;
            case 'tasks':
                const newTasks= [...jobInfo.tasks];
                newTasks[index] = event.target.value;
                setJobInfo({
                    ...jobInfo,
                    tasks: newTasks
                });
            break;
            case 'qual':
                const newQual = [...jobInfo.qual];
                newQual[index] = event.target.value;
                setJobInfo({
                    ...jobInfo,
                    qual: newQual
                });
            break;
            case 'active':
                setJobInfo({
                    ...jobInfo,
                    active: jobInfo.active === 0 ? 1 : 0
                });
            break;
            case 'info':
                const newInfo = [...jobInfo.info];
                newInfo[index] = event.target.value;
                setJobInfo({
                    ...jobInfo,
                    info: newInfo
                });
            break;
         }
    };
    
    const updateJob = async() => {

        try {

            const response = await fetch('/updateJobs', {
                method: 'POST',
                headers: {
                    'Content-Type' : 'application/json'
                },
                body: JSON.stringify(jobInfo)
            });

            if(response.ok) {
                const data = await response.json();
                console.log(data);
                setJobInfo({
                    id: data.id,
                    title: data.job_title,
                    description: data.job_description,
                    pensum: data.pensum,
                    address: data.address,
                    date: data.date,
                    tasks: data.job_tasks,
                    qual: data.job_qual,
                    active: data.active,
                    filename: data.filename !== null ? data.filename: ''
                });

                alert("job updated");
    
            }

        } catch(err) {
            console.error(err);
        }
    };

    //pdf file upload
    const ref = useRef();
    const [ selected, setSelected ] = useState(false);
    const [ uploaded, setUploaded ] = useState(false);
    const [ selectedFile, setSelectedFile ] = useState("Datei auswählen");
    const [ pdfUrl, setPdfUrl ] = useState('');

    const triggerFileUpload = () => {
        if (ref.current) {
            ref.current.click();
        }
    };
    const handleFileChange = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        console.log(ref.current.files[0]);
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file.name);
            setSelected(true);
        } else {
            setSelectedFile(null);
            setSelected(false);
        }

    };

    const uploadPdf = async(event)  => {

        event.preventDefault();

        const formData = new FormData();
        formData.append('id', jobInfo.id);
        formData.append('file', ref.current.files[0]);
        
        if(ref.current.files[0]) {
            try {
                const response = await fetch('/uploadPdf', {
                    method: 'POST',
                    body: formData
                });

                if(response.ok) {
                    alert("pdf uploaded");
                }
    
            } catch(err) {
                console.error(err);
            }
        }

    };
 
    const handleEditorChange = (content) => {
        setJobInfo((prevInfo) => ({
            ...prevInfo,
            description: content
        }));
    };

    const createNew = async() => {

        setJobSelected(!jobSelected);
        try {

            const response = await fetch('/createNew', {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json'
                }
            });

            if(response.ok) {
                const data = await response.json();
                console.log("see new", data);

                setJobInfo({
                    id: data.id,
                    title: data.job_title,
                    description: data.job_description,
                    pensum: data.pensum,
                    address: data.address,
                    date: data.date,
                    tasks: Array.isArray(data.tasks) ? data.tasks : [],
                    qual: Array.isArray(data.qual) ? data.qual : [],
                    active: data.active,
                    filename: data.filename !== null ? data.filename: ''
                });
            }

        } catch(err) {
            console.error(err);
        }

    };

    const deleteJob = async() => {

        setJobSelected(!jobSelected);
        try {

            const response = await fetch(`/deleteJob?id=${jobInfo.id}`, {
                method: 'GET',
                headers: {
                    'Content-Type' : 'application/json'
                }
            });

        } catch(err) {
            console.error(err);
        }

    };
 
    return(
        <div className='login-page'>
            
            {!loggedIn &&
                <div className = "login-container">
                <div className='login-avatar'>
                    <svg fill="white" viewBox="0 0 64 64" height = "100%" width = "100%"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><title></title><path d="M32,32.86a9.22,9.22,0,1,1,9.21-9.22A9.23,9.23,0,0,1,32,32.86Zm0-15.43a6.22,6.22,0,1,0,6.21,6.21A6.21,6.21,0,0,0,32,17.43Z"></path><path d="M32,56.64a24.68,24.68,0,0,1-15.22-5.27,1.52,1.52,0,0,1-.57-1.06c0-.16,0-.31,0-.47a15.8,15.8,0,1,1,31.6,0c0,.16,0,.31,0,.47a1.52,1.52,0,0,1-.57,1.06A24.68,24.68,0,0,1,32,56.64ZM19.21,49.45a21.62,21.62,0,0,0,25.58,0,12.8,12.8,0,0,0-25.58,0Zm27.08.74h0Z"></path><path d="M32,56.64a24.65,24.65,0,1,1,15.22-5.27A24.68,24.68,0,0,1,32,56.64Zm0-46.28A21.63,21.63,0,0,0,18.64,49a21.64,21.64,0,0,0,35-17A21.67,21.67,0,0,0,32,10.36Z"></path></g></svg>
                </div>

                <input type = "text" placeholder = "Nutzernamen" onChange = {(event) => setUserData({...userData, name: event.target.value})}/>
                <input type = "password" placeholder='Kennwort' onChange = {(event) => setUserData({...userData, password: event.target.value})}/>

                <button type = "button" onClick = {adminLogin}>Login</button>
                </div>
            }

            {(!jobSelected && loggedIn) &&
                <div className = "job-holder">
                    <h1>Aktuelles</h1>
                    {allJobs.map((item, index) => {

                        return(
                            <div key = {index} className = "jobs-list" onClick = {() => selectJob(item)}>
                                
                                <div style = {{width: '78%', minHeight: '15vh', display: 'flex', alignItems: 'center'}}>
                                    <h2>{item.job_title}</h2>
                                </div>

                                <div className = "job-status">

                                    <div className='job-active'>
                                        <p>Aktiv: </p>
                                        
                                        <div>{item.active === 1 ? <svg fill="white" height = '90%' viewBox="0 0 335.765 335.765"><g></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <g> <g> <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 "></polygon> </g> </g> </g></svg> : ''}</div>
                                    </div>
                                </div>

                            </div>
                        );
                    })}

                    <button className='create-button' onClick = {createNew}>Erstellen</button>

                </div>
            }

            
                    {(jobSelected && loggedIn) &&
                <div className = "admin-panel">

                <svg className='back-button' onClick = {() => setJobSelected(false)} viewBox="0 0 512 512"><g></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <g> <path d="M256,0C114.837,0,0,114.837,0,256s114.837,256,256,256s256-114.837,256-256S397.163,0,256,0z M384,277.333H179.499 l48.917,48.917c8.341,8.341,8.341,21.824,0,30.165c-4.16,4.16-9.621,6.251-15.083,6.251c-5.461,0-10.923-2.091-15.083-6.251 l-85.333-85.333c-1.963-1.963-3.52-4.309-4.608-6.933c-2.155-5.205-2.155-11.093,0-16.299c1.088-2.624,2.645-4.971,4.608-6.933 l85.333-85.333c8.341-8.341,21.824-8.341,30.165,0s8.341,21.824,0,30.165l-48.917,48.917H384c11.776,0,21.333,9.557,21.333,21.333 S395.776,277.333,384,277.333z"></path> </g> </g> </g></svg>
                    
                    <div>
                    <div className='job-infos'>
                        <p className = "inline-p" style = {{marginRight: '2vh'}}>Titel: </p><input value = {jobInfo.title} onChange = {(event) => changeJob(event, 'title')} style = {{padding: '0', margin: '0'}}/>
                        <br /><br /><p className = "inline-p" style = {{marginRight: '2vh'}}>Addresse: </p><input value = {jobInfo.address} onChange = {(event) => changeJob(event, 'address')} style = {{padding: '0', margin: '0'}}/>
          

                        <div style = {{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '2vh'}}><p style = {{marginRight: '2vh'}}>Pensum: </p><input value = {jobInfo.pensum} onChange = {(event) => changeJob(event, 'pensum')} style = {{padding: '0', margin: '0', maxWidth: '25%'}}/><p style = {{marginRight: '2vh', marginLeft: '2vh'}}>Startdatum: </p><input value = {jobInfo.date} onChange = {(event) => changeJob(event, 'date')} style = {{padding: '0', margin: '0', maxWidth: '25%'}}/><br/></div>

                        <TextEditor value = {jobInfo.description} onChange={handleEditorChange}/>

                        <div style = {{marginTop: '2vh'}}>
                            <p>Aufgaben: </p>
                            {jobInfo.tasks.length > 0 &&
                                jobInfo.tasks.map((item, index) => {
                                    return (
                                        <div key = {index} style = {{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '2vh', marginBottom: '2vh'}}>
                                            <textarea className = "task-qual" value = {item} onChange = {(event) => changeJob(event, 'tasks', index)}/><svg onClick = {() => deleteTask(index, 'task')} viewBox="0 0 24 24" height="4.5vh"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#F27208ff"></path> </g></svg>
                                        </div>
                                    )
                                })
                            }
                            <div style = {{marginTop: '2vh'}} onClick = {() => createNewTask('task')}>+ Aufgabe hinzufügen</div>
                        </div>

                        <div>
                            <p>Profil: </p>
                            {jobInfo.qual.length > 0 &&
                                jobInfo.qual.map((item, index) => {
                                    return (
                                        <div key = {index} style = {{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', gap: '2vh', marginBottom: '2vh'}}>
                                            <textarea className = "task-qual" value = {item} onChange = {(event) => changeJob(event, 'qual', index)}/><svg onClick = {() => deleteTask(index, 'qual')} viewBox="0 0 24 24" height="4.5vh"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM15.75 12C15.75 12.4142 15.4142 12.75 15 12.75H9C8.58579 12.75 8.25 12.4142 8.25 12C8.25 11.5858 8.58579 11.25 9 11.25H15C15.4142 11.25 15.75 11.5858 15.75 12Z" fill="#F27208ff"></path> </g></svg>
                                        </div>
                                    )
                                })
                            }
                            <div style = {{marginTop: '2vh'}} onClick = {() => createNewTask('qual')}>+ Qualifikation hinzufügen</div>

                        </div>

                    </div>

                    <div className='job-active-settings'>
                                <p>Aktiv: </p>
                                        
                                <div className = "job-active-settings-box" onClick = {(event) => changeJob(event, 'active')}>{jobInfo.active === 1 ? <svg fill="white" height = '90%' viewBox="0 0 335.765 335.765"><g></g><g strokeLinecap="round" strokeLinejoin="round"></g><g> <g> <g> <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795 "></polygon> </g> </g> </g></svg> : ''}</div>

                                <p>Löschen: </p>

                                <div className = "job-active-settings-box" onClick = {deleteJob}></div>

                    </div>

                    <button onClick = {() => updateJob()} style = {{marginTop: '2vh', marginBottom: '2vh', marginLeft: '50%', transform: 'translateX(-50%)'}}>Update</button>
                    </div>

                    <div style = {{display: largeScreen ? 'flex' : 'block', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1vh' }}>
                    {!uploaded &&
                        <div className='upload-pdf' onClick = {triggerFileUpload}>

                        <input ref = {ref} type = "file" accept="application/pdf" onChange={(event) => handleFileChange(event)} hidden/>
                        {(!selected && !jobInfo.filename) &&
                            <div className = "upload-pdf-svg">
                                <p>Datei auswählen</p>
                                <svg height="15vh" width="100vw" viewBox="0 0 312.602 312.602" fill="white"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M251.52,137.244c-3.966,0-7.889,0.38-11.738,1.134c-1.756-47.268-40.758-85.181-88.448-85.181 c-43.856,0-80.964,32.449-87.474,75.106C28.501,129.167,0,158.201,0,193.764c0,36.106,29.374,65.48,65.48,65.48h54.782 c4.143,0,7.5-3.357,7.5-7.5c0-4.143-3.357-7.5-7.5-7.5H65.48c-27.835,0-50.48-22.645-50.48-50.48c0-27.835,22.646-50.48,50.48-50.48 c1.367,0,2.813,0.067,4.419,0.206l7.6,0.658l0.529-7.61c2.661-38.322,34.861-68.341,73.306-68.341 c40.533,0,73.51,32.977,73.51,73.51c0,1.863-0.089,3.855-0.272,6.088l-0.983,11.968l11.186-4.367 c5.356-2.091,10.99-3.151,16.747-3.151c25.409,0,46.081,20.672,46.081,46.081c0,25.408-20.672,46.08-46.081,46.08 c-0.668,0-20.608-0.04-40.467-0.08c-19.714-0.04-39.347-0.08-39.999-0.08c-4.668,0-7.108-2.248-7.254-6.681v-80.959l8.139,9.667 c2.667,3.17,7.399,3.576,10.567,0.907c3.169-2.667,3.575-7.398,0.907-10.567l-18.037-21.427c-2.272-2.699-5.537-4.247-8.958-4.247 c-3.421,0-6.686,1.548-8.957,4.247l-18.037,21.427c-2.668,3.169-2.262,7.9,0.907,10.567c1.407,1.185,3.121,1.763,4.826,1.763 c2.137,0,4.258-0.908,5.741-2.67l7.901-9.386v80.751c0,8.686,5.927,21.607,22.254,21.607c0.652,0,20.27,0.04,39.968,0.079 c19.874,0.041,39.829,0.081,40.498,0.081c33.681,0,61.081-27.4,61.081-61.08C312.602,164.644,285.201,137.244,251.52,137.244z"></path> </g></svg>
                            </div>
                        }

                        {(!selected && jobInfo.filename) &&
                            <div className = "pdf-image">
                                <img src = "/icons/pdf.png"/>
                                <p>{jobInfo.filename}</p>
                            </div>
                        }
    
                        {selected && 
                            <div className='pdf-image'>
                                <img src = "/icons/pdf.png"/>
                                <p>{selectedFile}</p>
                            </div>
                        }
    
                        </div>
                    }

                    <div className = "upload-pdf-button">
                        
                        {!uploaded &&
                            <button type = "button" onClick = {(event) => uploadPdf(event)}>Upload</button>
                        }

                    </div>

                    </div>

                </div>
            }

        </div>
    );
};
export default Admin;