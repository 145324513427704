import { expandNavbar, selectExpand, setRender, currentRender, setPosition, navPosition } from "../redux/navbarSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { Link, useNavigate } from "react-router-dom";

const NavBar = () => {

    const [ largeScreen, setLargeScreen ] = useState(window.innerWidth > 900);
    const [ dropdown, setDropdown ] = useState(false);
    const navbarExpanded = useSelector(selectExpand);
    const dispatch = useDispatch();

    const handleDropdown = () => {
        setDropdown(!dropdown)
    };
    const container = document.getElementById('root');
    const actualRender = useSelector(currentRender);
    const navbarPos = useSelector(navPosition);
    const handleToggle = () => {
        if(!navbarExpanded) {
            dispatch(expandNavbar(true));
            container.style.overflowY = 'hidden';
            setNavbar(false);
        } else {
            dispatch(expandNavbar(false));
            container.style.overflowY = 'auto';
            if((actualRender === 'start' || actualRender === 'home') && scrollPosition < 50) {
                setNavbar(false);
            } else {
                setNavbar(true);
            }
        }
    }
 
    const [ scrollPosition, setScrollPosition ] = useState(0);
    const [ navbar, setNavbar ] = useState(false);
    const currentScroll = container.scrollTop;    
    const handleScroll = () => { 
        
            if(currentScroll < scrollPosition && currentScroll > 800) {
                setNavbar(true);
            } else {
                setNavbar(false);
            }
        setScrollPosition(currentScroll);
    };

    const navigate = useNavigate();
    const handleTextClick  = (input) => {
        
        navigate("/");
        dispatch(expandNavbar(false));
        container.style.overflowY = 'auto';

        setTimeout(() => {

            if(input === 'locationOne') {
                dispatch(setRender('locationOne'));
            } else if(input === 'locationTwo') {
                dispatch(setRender('locationTwo'));
            } else if(input === 'portrait') {
                dispatch(setRender('portrait'));
            } else if(input === 'contact') {
                dispatch(setRender('contact'));
            } else if(input === 'facts') {
                dispatch(setRender('facts'));
            } else if(input === 'home') {
                dispatch(setRender('home'));
                setScrollPosition(0);
            }

        }, 400);
    }

    useEffect(() => {
        const handleResize = () => {
            setLargeScreen(window.innerWidth > 900);
        };

        window.addEventListener('resize', handleResize);
        container.addEventListener('scroll', handleScroll);

        return() => {
            window.removeEventListener('resize', handleResize);
            container.removeEventListener('scroll', handleScroll);
        };
    }, [actualRender, navbar, currentScroll, scrollPosition]);


    const menuRef = useRef();
    const iconRef = useRef();
    const [ menuWidth, setMenuWidth ] = useState(0);
    useEffect(() => {

        if(menuRef.current && iconRef.current) {
            setMenuWidth(menuRef.current.offsetWidth + iconRef.current.offsetWidth + window.innerWidth * 0.01);
        }

    }, [menuWidth]);

    return(
        
        <nav className = "navbar">
            {largeScreen &&
                <>
                <div className = "nav-menu"><h1 ref={menuRef} style = {{background: 'transparent'}}>Menu</h1><img ref={iconRef} src = "/icons/arrow-white.png" onClick = {handleDropdown} style = {{transform: dropdown ? 'rotate(180deg)' : ''}}/></div>
                <AnimatePresence>
                    {dropdown &&
                        <motion.div 
                        className = "desktop-nav"
                        initial = {{height: 0, opacity: 0,}}
                        animate = {{height: 'auto', opacity: 1}}
                        transition = {{duration: 0.5}}
                        exit= {{height: 0, opacity: 0, transition: {duration: 0.3, delay: 0.2}}}
                        >

                        {dropdown &&
                            <motion.div
                            initial = {{opacity: 0, scale: 0.5}}
                            animate = {{opacity: 1, scale: 1}}
                            transition={{duration: 0.5, delay: 0.5}}
                            exit={{opacity: 0, scale: 0.5, transition: {duration: 0.3}}}
                            style = {{margin: 0, padding: 0, width: menuWidth}}
                            className="desktop-nav-flex"
                        >
                        <div className="desktop-nav-item">
                            <img src = "/icons/home.png" alt = "home" onClick = {() => handleTextClick('home')} style = {{height: '6vh', width: 'auto', transform: 'translateX(0%)'}}/>
                        </div>

                        <div className="desktop-nav-item">
                            <p onClick = {() => handleTextClick('portrait')}>Über Uns</p>
                        </div>
    
                        <div className="desktop-nav-item">
                            <p onClick = {() => handleTextClick('facts')}>Fakten & Zahlen</p>
                        </div>
    
                        <div className="desktop-nav-item">
                            <p  onClick = {() => handleTextClick('contact')}>Kontakt</p>
                        </div>
    
                        <div className="desktop-nav-item">
                            <p  onClick = {() => handleTextClick('location')}>Standorte</p>
                        </div>

                        <div className="desktop-nav-item"><Link onClick = {() => handleTextClick()} to='/news' style = {{textDecoration: 'none', color: 'white', fontFamily: 'Avenir'}}><p>News</p></Link></div>
    
                        </motion.div>
                        }

                    </motion.div>
                    }
                    </AnimatePresence>
                </>                    
            }

            {!largeScreen &&
                <>
                        <button className = "navbar-button" onClick = {handleToggle}>
                            <img src= "/icons/toggle.png"/>
                        </button>

                        {(actualRender !== 'home' && navbar) && (
                            <button className = "nav-button" onClick = {handleToggle}>
                                <img src= "/icons/toggle.png"/>
                            </button>
                        )}

                <AnimatePresence>
                    {navbarExpanded &&
                            <motion.div 
                            key = "navbar-expanded"
                            className = "navbar-expanded"
                            initial = {{width: '1vw', height: '0', x: '100vw', y: '50vh', opacity: 0 }}
                            animate = {{width: '100vw', height: '100vh', x: 0, y: 0, opacity: 1}}
                            exit = {{width: '1vw', height: '0', x: '100vw', y: '50vh', opacity: 0, transition: { duration: 0.3, delay: 0.2}}}
                            transition={{ duration: 0.5, ease: "easeInOut" }}
                            >

                                <motion.div 
                                className = "navbar-flex"
                                initial = {{opacity: 0, scale: 0.5}}
                                animate = {{opacity: 1, scale: 1}}
                                transition={{ duration: 0.5, delay: 0.5}}
                                exit={{opacity: 0, scale: 0.5, transition: {duration: 0.3}}}
                                >
                                    <button className = "navbar-button-expanded" onClick = {handleToggle}>
                                    <img src= "/icons/close.png" style = {{height: navbarExpanded ? '30px' : '65px', width: navbarExpanded ? '30px' : '55px'}}/>
                                </button>

                                    <div className="navbar-expanded-text">
                                    <div>
                                        <img src = "/images/logos/RockLogo.png" alt = "home" onClick = {() => handleTextClick('home')} style = {{height: '18vh', width: 'auto', marginLeft: '-7vh'}}/>
                                    </div>
                                <div>
                                    <p onClick = {() => handleTextClick('portrait')}>Über Uns</p>
                                </div>

                                <div><Link onClick = {() => handleTextClick()} to='/news' style = {{textDecoration: 'none', color: 'white', fontFamily: 'Avenir'}}><p>News</p></Link></div>

                                <div>
                                    <p onClick = {() => handleTextClick('facts')}>Fakten & Zahlen</p>
                                </div>

                                <div className = "mobile-locations">
                                    <p style = {{marginBottom: '10px', fontSize: '1em', fontWeight: 'bold'}}>Standorte</p> 
                                    <p onClick = {() => handleTextClick('locationOne')}><img src = '/icons/next.png' className = "next-arrow"/>Kleefeld Quartier</p>
                                    <p onClick = {() => handleTextClick('locationTwo')}><img src = '/icons/next.png' className = "next-arrow"/>Engestrasse</p>  
                                </div>

                                <div>
                                    <p onClick = {() => handleTextClick('contact')}>Kontakt</p>
                                </div>

                                    </div>
                                </motion.div>
                                
                                </motion.div>
                    }
                </AnimatePresence>

                </>
            }

        </nav>

    );
}

export default NavBar; 